/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --color-wheat: wheat;
}
.ProductDetailStickyHeader {
    .ProductPrice-HighPrice {
        color: white;
    }
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    max-width: 100vw;
    left: 0;
    &-Top {
        transition: all 0.3s linear;
        transform: translateY(-50%);
        padding: 5px 15px;
        // z-index: 0;
    }
    &-NotTop {
        min-height: 105px;
        padding: 5px 15px;
        background-color: var(--primary-dark-color);
        transition: all 0.3s linear;
        transform: translateY(0);
        display: flex;
        align-items: flex-start;
        // justify-content: space-around;
    }
    &-ProductDetail {
        width: 100%;
        display: grid;
        grid-template-columns: 4fr 2fr;
    }
    &-ProductDetailAction {
        justify-self: flex-end;
    }
    &-ProductDetailOutOfStockAction {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: -1;
    }
    &-ProductImagePlaceHolder {
        @include desktop {
            display: flex;
            height: auto;
            width: 10%;
            padding: 10px 0;
            img {
                background-color: $white;
            }
        }
    }
    &-ProductImage {
        display: inline-block;
        align-self: center;
        text-align: center;
        width: 17%;
        img {
            background-color: transparent !important;
            width: 60px;
        }
    }
    &-ProductInfo {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    &-ProductName {
        h3 {
            margin-top: 0px;
            margin-bottom: 0px;
            color: var(--color-wheat);
            letter-spacing: 1px;
            padding-left: 10px;
        }
    }
    &-ProductWeight {
        span {
            font-size: 17px;
            color: var(--color-wheat);
            margin-left: 5px;
        }
    }
    &-ProductDetailLinks {
        ul {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            justify-content: center;
            width: 100%;
            @include ultra-narrow-desktop {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                width: 115%;
            }
            @media screen and (min-width: 811px) and (max-width: 860px) {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
                width: 115% !important;
            }
            @media screen and (min-width: 1160px) and (max-width: 1360px) {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                width: 105%;
            }
            li {
                margin-block: 0px;
                padding: 2px 5px;
                // margin-inline: 5px;
                &::before {
                    content: "";
                }
                a > h5 {
                    margin: 0;
                    letter-spacing: 1.5px;
                    color: lightgrey;
                    text-align: center;
                    font-weight: 600;
                    transition: all 0.05s linear;
                    border: 1px solid lightgrey;
                    border-radius: 30px;
                    padding: 5px;
                    transform: scale(1);
                    &:hover {
                        transform: scale(1.05);
                        border: 1px solid var(--primary-alt-light-color);
                        color: var(--primary-alt-light-color);
                        box-shadow: 0px 0px 7px 0px
                            var(--primary-alt-light-color);
                    }
                }
            }
        }
    }
    &-ProductPrice {
        // justify-self: flex-end;
        align-self: center;
        text-align: center;
        // margin-top: 13px;
    }
    &-Price {
        font-size: 18px;
        letter-spacing: 0.5px;
        color: var(--color-wheat);
    }
    &-OutOfStockBtn {
        width: fit-content;
        align-self: flex-end;
        padding: 5px 20px;
        border-radius: 20px;
    }
    &-AddToCart {
        width: fit-content !important;
        justify-self: flex-end;
        align-self: center;
        padding: 5px 20px;
        border-radius: 30px !important;
        background-color: var(--primary-alt-light-color) !important;
        border: none !important;
        transition: all 0.2s linear;
        transform: scale(1);
        &:hover {
            transform: scale(1.05);
            box-shadow: 0px 0px 10px rgb(229, 239, 229);
            border: none !important;
            border-radius: 30px !important;
            width: auto !important;
            padding: 5px 20px !important;
            background-color: var(--primary-alt-base-color) !important;
        }
    }
    &-QuickDeliveryAddressAction {
        cursor: pointer;
        background-color: var(--primary-alt-light-color);
        padding: 15px;
        color: white;
        border-radius: 30px;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 1.5px;
        transition: all 0.2s linear;
        transform: scale(1);
        &:hover {
            background-color: var(--primary-alt-light-color);
            color: white;
            transform: scale(1.02) !important;
        }
    }
    // .ShopMenuWrapper{
    //     align-self: center;
    //     width: 30px;
    // }
    // .Menu-Item:first-child .Menu-Link .Menu-ItemCaption{
    //     color: white;
    //     display: none;
    // }
    // .Menu-Item:first-child a.Menu-Link::before{
    //     color: white;
    // }
    // .Menu-SubCategoriesWrapper_isVisible{
    //     // top:60px;
    // }
}
