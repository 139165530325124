/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
@mixin narrow-mobile {
    @media (max-width: 390px) {
        @content;
    }
}
@mixin narrow-tablet {
    @media (min-width: 650px) and (max-width: 811px) {
        @content;
    }
}
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.ProductActions {
    line-height: 16px;
    @include desktop {
        align-self: center;
        max-width: 600px;
        min-width: 400px;
    }
    @media screen and (min-width: 1300px) and (max-width: 1800px) {
        left: 10%;
    }
    @media screen and (min-width: 1800px) {
        left: 25%;
    }

    &-Brand {
        font-weight: bold;
        opacity: 0.48;
        font-size: 12px;
    }

    &-Title {
        margin-block: 8px;

        @include desktop {
            width: 90%;
            line-height: 40px;
            letter-spacing: 1px;
            margin: 0;
            margin-top: 10px;
            display: inline;
        }

        @include ultra-narrow-desktop {
            display: block;
            width: 80%;
        }
        @include mobile {
            font-weight: 400;
            font-size: 14px;
        }
    }

    &-ProductWeight {
        @include desktop {
            font-size: 15px;
            line-height: 30px;
            letter-spacing: 0.5px;
            font-weight: 700;
            margin-left: 0.1em;
        }
        @include ultra-narrow-desktop {
            margin-left: 0;
        }
    }

    &-ShortDescription {
        font-size: 14px;
        line-height: 20px;
        margin-block: 16px;
    }

    &-Stock {
        font-weight: bold;

        @include desktop {
            display: grid;
            grid-template-columns: 0.3fr 1fr;
            justify-content: center;
            align-items: center;
        }

        @include mobile {
            padding: 3px 10px;
            display: flex;
        }
    }
    &-StockStatusLabel {
        @include desktop {
            font-size: 16px;
            letter-spacing: 1px;
            align-self: center;
            padding: 3px 0px 0px 0px;
        }
        @include mobile {
            align-self: center;
            padding: 2px 0px 0px 3px;
            // margin-bottom: 5px;
        }
    }

    &-Sku {
        & + .ProductActions-Sku {
            margin-inline-start: 1ex;
        }
    }

    &-Review {
        font-weight: 700;
        cursor: pointer;
        inset-block-end: -2px;
        color: var(--primary-base-color);
        @include desktop {
            font-size: 15px;
            letter-spacing: 0.7px;
            padding: 8px 15px;
            color: var(--primary-alt-base-color);
            border: 1px solid var(--primary-alt-base-color);
            border-radius: 5px;
            transition: all 0.15s linear;
            letter-spacing: 1px;
            &:hover {
                background-color: var(--primary-base-color);
                border-color: var(--primary-base-color);
                color: white;
            }
        }
        @include mobile {
            border: 1px solid rgb(154, 168, 155);
            padding: 5px 10px;
            color: var(--primary-alt-base-color);
            border-radius: 5px;
            transition: all 0.15s linear;
            font-size: 14px;
            letter-spacing: 0.5px;
        }
    }

    &-Section {
        &_type {
            &_sku {
                display: flex;

                @include mobile {
                    flex: 1;
                    align-items: flex-end;
                    flex-direction: column;
                }
            }

            &_alerts {
                inset-block-start: 10px;
            }
        }
    }

    &-Schema {
        min-width: 100px;
        @include desktop {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 3fr;
            column-gap: 20px;
            justify-items: end;
            .ProductPrice {
                min-height: 0;
                letter-spacing: 1px;
                span {
                    margin: 0;
                }
            }
        }
        @include mobile {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 3fr;
            column-gap: 20px;
            justify-items: end;
            .ProductPrice {
                min-height: 0;
                letter-spacing: 1px;
                span {
                    margin: 0;
                }
            }
        }
        @include narrow-mobile {
            display: block;
        }
        // @include ultra-narrow-desktop{
        //     display: block;
        // }
    }

    &-SchemaUrl {
        display: none;
    }
    &-TagLine {
        margin-bottom: 0px;
        margin-top: 0;
        letter-spacing: 0.5px;
        font-size: 15px;
        font-style: italic;
        color: rgb(113, 113, 113);
    }
    &-PriceWrapper {
        @include desktop {
            margin-block-start: 24px;
            margin-top: 0px;
        }
    }
    &-RewardsPoint {
        // @include desktop {
        p {
            background-color: var(--primary-alt-light-color);
            border-radius: 3px;
            border-left: 1px solid var(--primary-alt-light-color);
            position: relative;
            color: white;
            font-weight: 300;
            padding: 2px 10px;
            line-height: 33px;

            @include desktop {
                margin: 0;
                font-size: 16px;
                letter-spacing: 1.2px;
            }
            @include mobile {
                width: max-content;
                margin-top: 0.6em;
                margin-left: 10px;
                margin-bottom: 10px;
                left: 0;
                font-size: 16px;
                letter-spacing: 1px;
            }

            @include tablet {
                width: max-content;
            }

            @include narrow-tablet {
                margin: 0;
                width: max-content;
            }
            @include narrow-mobile {
                width: 100%;
            }

            /* Makes the triangle */
            &::before {
                content: "";
                position: absolute;
                display: block;
                left: -18px;
                width: 0;
                top: 0;
                height: 0;
                border-top: 18px solid transparent;
                border-bottom: 19px solid transparent;
                border-right: 19px solid var(--primary-alt-light-color);
            }
            /* Makes the circle */
            &::after {
                content: "";
                background-color: rgb(234, 224, 194);
                border-radius: 50%;
                width: 7px;
                height: 7px;
                display: block;
                position: absolute;
                left: -7px;
                top: 15px;
            }
        }
        // }
    }

    &-ActionsWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: 12px;

        @include desktop {
            margin-block: 18px;
        }

        .ProductCompareButton {
            margin-inline-start: auto;
        }

        &_isWithoutPriceTotal .ProductActions-Section_type_sku {
            @include mobile {
                float: end;
            }
        }
    }

    &-CartActionsContainer {
        position: fixed;
        inset-block-end: var(--footer-total-height);
        z-index: 5;
        background-color: var(--color-white);
        width: 100%;
        padding: 12px 16px;
        border-block-start: 1px solid var(--primary-divider-color);
        inset-inline-start: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px -3px 20px rgb(182, 181, 181);
    }

    &-AddToCartMobileAction {
        display: flex;

        .ProductWishlistButton {
            margin-inline: 10px 4px;
            margin-block: auto;
        }
    }

    &-AddToCartWrapper {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 2fr;
        margin-block-start: 24px;
        gap: 10px;
        &_isGroupedProduct {
            display: block;
        }

        .ProductActions-AddToCart {
            margin-inline-end: 16px;
            @include desktop {
                border: 1px !important;
                border-radius: 10px !important;
                transition: all 0.2s linear;
                background-color: var(--primary-base-color);
                letter-spacing: 1px;
                &:hover {
                    background-color: var(--primary-light-color);
                    transform: scale(1.04);
                }
            }

            @include ultra-narrow-desktop {
                display: block;
                width: 100%;
                margin-block-start: 24px;
                margin-top: 5px;
            }
        }

        .ProductCompareButton,
        .ProductWishlistButton {
            margin-inline: 16px;
        }

        @include ultra-narrow-desktop {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &_isPrerendered {
            @include mobile {
                inset-block-end: 0;
            }
        }
    }
    .AddToCartActionWrapper {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr;
    }

    &-Qty {
        &.Field {
            margin-block: 0;

            @include desktop {
                margin-inline-end: 32px;
                input {
                    background: transparent;
                }
            }

            @include mobile {
                margin-inline-end: 12px;

                input {
                    min-width: 36px;
                }
            }
        }

        button {
            cursor: pointer;

            &:hover {
                border-color: var(--primary-dark-color);
            }
        }
    }

    &-AttributesWrapper {
        @include mobile {
            margin-block-end: 16px;
        }

        &.animate {
            animation: shake 820ms 0.5ms cubic-bezier(0.36, 0.07, 0.19, 0.97)
                both;
        }
    }

    &-GroupedItems {
        margin-block-start: 24px;
    }

    &-Reviews {
        display: flex;

        @include mobile {
            flex-direction: column;
            row-gap: 10px;
        }
        .ProductReviewRating {
            margin-inline-end: 24px;
        }
    }

    .ProductConfigurableAttributes-Expandable {
        margin-block-start: 24px;
    }

    .ExpandableContent:first-of-type {
        border-block-start: 0;
    }

    &-DesktopContentContainer,
    &-MobileContentContainer {
        height: auto;
        background-color: rgb(234, 225, 200);
        padding: 30px 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.6) inset,
            -7px 10px 10px 0px rgba(0, 0, 0, 0.3),
            7px 10px 10px 0px rgba(0, 0, 0, 0.3);
        @include mobile {
            margin-top: 2em;
        }
    }

    //NEW MODIFIED CODE

    &-ShareIcon {
        margin-right: 2em;
    }
    &-TopPart {
        top: -20px;
        left: 0;
    }
    &-NameWrapper {
        margin-bottom: 1em;
    }
    &-ProductWishlistWrapper {
        display: flex;
        position: absolute;
        right: 0%;
        top: 10px;
    }
    &-StockReviewWrapper {
        @include desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            top: -10px;
        }
        @include mobile {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            top: 0px;
        }
    }
    &-SavingStrip {
        border-radius: 10px;
        margin-top: 10px;
        display: grid;
        grid-template-columns: 0.1fr 1fr;
        justify-content: center;
        align-items: center;

        @include desktop {
            margin-block: 1em;
        }
        @include mobile {
            margin-top: 1.5em;
        }
        p {
            margin: 0;
            padding-inline: 0;
            @include desktop {
                font-size: 16px;
                letter-spacing: 0.6px;
            }
            @include mobile {
                font-size: 14px;
                letter-spacing: 0.6px;
            }
            strong {
                color: var(--primary-alt-base-color);
            }
        }
    }
    &-SavingStripIconWrapper {
        text-align: center;
        img {
            width: 60%;
        }
    }
    &-PriceWithGlobalSchemaWrapper {
        @include desktop {
            top: -10px;
            padding-bottom: 10px;
            border-bottom: 1px solid grey;
        }
        @include mobile {
            top: -10px;
            padding-bottom: 10px;
            border-bottom: 1px solid grey;
        }
    }
    &-HorizontalLine {
        border-top: 1px solid grey;
        top: 0px;
    }
    &-DescriptionRightIcon {
        &:before {
            content: "\2713";
            position: relative;
            top: 8px;
            margin-right: 7px;
            color: var(--primary-light-color);
            @include desktop {
                font-size: 25px;
            }
            @include mobile {
                font-size: 20px;
            }
        }
    }
    &-DescriptionWrapper {
        margin-top: 2em;
        display: grid;
        grid-template-columns: 0.07fr 1fr;
        p {
            font-size: 15px;
            letter-spacing: 0.5px;
            line-height: 30px;
            margin: 0;
            padding-inline: 0;
        }
    }
    &-AddToCartActionBlockWrapper {
        @include desktop {
            margin-top: 3em;
        }
    }

    &-QuickDeliveryLocationDesktopWrapper {
        margin-top: 3em;
    }

    &-DeliveryLocationSlidePane {
        border-radius: 5px;
        background-color: var(--primary-alt-light-color);
        padding: 10px 15px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: var(--button-color);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        height: var(--button-height);
        text-transform: uppercase;
        span {
            margin-top: 4px;
            margin-right: 5px;
        }
        &_isAvailable {
            width: 100%;
            padding: 10px 30px;
        }

        &_isDisabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    &-LocationAddressViewContainer {
        background-color: #e4f9ea;
        border-radius: 10px;
        padding: 15px 10px;
        margin-bottom: 10px;
        box-shadow: 0px 4px 15px rgb(216, 216, 216);
        border: 1px dashed rgb(118, 118, 118);
        @include desktop {
            margin-top: 4em;
        }
    }
    &-LocationAddressViewWrapper {
        p {
            letter-spacing: 1px;
            color: #1f9755;
            font-weight: 600;
            font-size: 12px;
            margin-bottom: 0;
            margin-right: 10px;
            line-height: 1.7;
            @include mobile {
                margin-right: 5px;
            }
        }
    }
    &-LocationAddressViewOkIcon {
        position: absolute;
        top: -25px;
        right: -20px;
        background-color: #2ba964;
        height: 28px;
        width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    &-ZomatoRouteBtn {
        background-color: #cb202d;
        padding: 10px 20px;
        width: 100%;
        font-size: 15px;
        letter-spacing: 1px;
        font-weight: bolder;
        color: white;
        border-radius: 10px;
        transition: all 0.2s linear;
        cursor: pointer;
        height: var(--button-height);
        text-transform: uppercase;
        @include desktop {
            margin-top: 2em;
        }
        &:hover {
            background-color: #cc2d3a;
            box-shadow: 0px 0px 10px rgb(115, 115, 115);
            transform: scale(1.05);
        }
    }

    &-InstructionsViewContainer {
        &_haveInstruction {
            margin-top: 1em;
        }
    }

    &-InstructionsViewWrapper {
        p {
            letter-spacing: 1px;
            color: var(--primary-dark-color);
            font-weight: 600;
            font-size: 12px;
            margin-bottom: 0;
            margin-right: 10px;
            line-height: 1.7;
            font-style: italic;
            @include mobile {
                margin-right: 5px;
            }
            &:before {
                content: "\2713";
                top: 2px;
                color: var(--primary-success-color);
                left: 0px;
                position: relative;
                font-size: 1.4em;
                font-weight: 700;
                margin-right: 10px;
            }
        }
    }

    .ProductPrice-HighPrice {
        color: red;
    }
    [type="number"] {
        background-color: transparent;
    }

    //QUICK DELIVERY ITEM RELATED STYLE
    &-SelectQuickDeliverableItemsButton {
        cursor: pointer;
        padding: 15px 20px;
        background-color: var(--primary-light-color);
        color: white;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
        border-radius: 10px;
        width: 100%;
        @include mobile {
            border-radius: 5px;
        }
        &:hover {
            background-color: var(--primary-light-color) !important;
            color: white;
        }
    }
}

.CartActionAvailableStatusClass {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.CartActionNotAvailableStatusClass {
    display: block;
}
.productActionVideo {
    width: 100%;
    border-radius: 10px;
    margin-top: 15px;
    @include mobile {
    }
    @include desktop {
        height: 300px;
    }
}
.productActionAudio {
    @include mobile {
        width: 100%;
        margin-bottom: 10px;
    }
    @include desktop {
        margin-bottom: 2em;
    }
}

@keyframes shakeInput {
    from {
        transform: translateX(7px);
    }
    to {
        transform: translateX(-7px);
    }
}
@-webkit-keyframes shakeInput {
    from {
        -webkit-transform: translateX(7px);
    }
    to {
        -webkit-transform: translateX(-7px);
    }
}
