/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --product-list-load-button-background: var(--secondary-base-color);
}

.ProductList {
    @include desktop {
        grid-column: 2;
        padding-block-end: 24px;
    }
    @include mobile {
        padding-inline: 0px;
        padding-bottom: 4.5em;
    }

    &-ProductsMissing {
        text-align: center;

        @include desktop {
            padding: 12px;
            background-image: radial-gradient(
                circle at center center,
                beige 30%,
                rgb(209, 214, 194) 70%
            );
            border-radius: 10px;
        }
        @include mobile {
            background-image: radial-gradient(
                circle at center center,
                beige 30%,
                rgb(209, 214, 194) 70%
            );
            box-shadow: 0px 10px 20px rgb(200, 200, 200);
            border-radius: 10px;
            padding: 14px;
            justify-self: center;
            align-self: center;
        }
    }
    &-ProductsMissingContent {
        h2 {
            color: var(--primary-base-color);
            text-transform: none;
            @include desktop {
                letter-spacing: 1px;
            }
            @include mobile {
                letter-spacing: 1px;
            }
        }
        h3 {
            font-weight: 200;
            line-height: 40px;
            letter-spacing: 1px;
            text-transform: none;
            strong {
                color: var(--primary-alt-dark-color);
            }
        }
    }
    .NotifyMe {
        @include desktop {
            letter-spacing: 1px;
            border: none;
            background-color: var(--primary-alt-base-color);
            border-radius: 10px;
            &:hover {
                background-color: var(--primary-alt-light-color);
                border-radius: 10px;
                border: none;
            }
        }
        @include mobile {
            letter-spacing: 1px;
            border: none;
            background-color: var(--primary-alt-base-color);
            border-radius: 10px;
            &:hover {
                background-color: var(--primary-alt-light-color);
                border-radius: 10px;
                border: none;
                color: white;
            }
        }
    }

    &-More {
        min-height: 36px;

        @include mobile {
            min-height: 42px;
        }
    }

    &-LoadButton {
        background-color: var(--category-product-list-load-button-background);
        cursor: pointer;
        font-size: 17px;
        margin-block-end: 12px;
        padding: 1em 0;
        text-align: center;

        @include mobile {
            margin-block-end: 14px;
        }
    }
}
