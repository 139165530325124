/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductConfigurableAttributes {
    font-size: 14px;
    line-height: 20px;

    &-SwatchList,
    &-DropDownList {
        text-align: center;
        margin-block-start: 2px;
        margin-block-end: 12px;
    }

    &-Title {
        font-weight: 700;
        margin-block: 24px 12px;
    }

    &-SwatchList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding-inline-start: 3px;
        padding-block-start: 3px;
    }

    &-DropDownList {
        @include desktop {
            width: 100%;
        }
        @include mobile {
            padding: 10px;
            padding-left: 20px;
            box-shadow: 0px 0px 10px rgb(228, 228, 228);
            border-radius: 10px;
            margin-inline: 10px;
            margin-top: 10px;
        }
    }

    &-Placeholder {
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        height: var(--option-size);
        width: var(--option-size);
        margin-block: var(--option-margin-block);
        margin-inside: var(--option-margin-inline);
    }

    &-Expandable:first-of-type {
        border-block-start: none;
        @include mobile {
            margin-bottom: 10px;
        }
    }

    &-Expandable.ExpandableContent:last-of-type {
        border-block-end: none;
        @include mobile {
            margin-top: 10px;
        }
    }
}
