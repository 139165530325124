/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --product-review-item-border: #d8d8d8;
}

.ProductReviewItem {
    word-break: break-word;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgb(225, 225, 225);
    @include mobile {
        box-shadow: 0px 0px 8px rgb(225, 225, 225);
        margin-inline: 10px;
    }

    &:last-child {
        margin-block-end: 0;
    }

    &::before {
        content: none;
    }

    &-MiddlePartContainer {
        margin-top: 10px;
        @include desktop {
            display: flex;
            justify-content: space-between;
        }
        @include mobile {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &-ReviewTitleContainer {
        border-bottom: 1px solid var(--primary-alt-light-color);
        padding-bottom: 3px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        @include desktop{
            flex-direction: row;
        }
    }
    &-ReviewTitle {
        margin-bottom: 0px;
        font-weight: 700;
        color: var(--primary-light-color);
        @include desktop {
            font-size: 16px;
            text-transform: capitalize;
            letter-spacing: 1px;
        }
        @include mobile {
            line-height: 20px;
            font-size: 16px;
            text-transform: capitalize;
            letter-spacing: 1px;
        }
    }

    &-ReviewNickName {
        color: gray;
        margin-bottom: 0px;
        font-style: italic;
        @include desktop {
            font-size: 12px;
            text-transform: capitalize;
            letter-spacing: 1px;
        }
        @include mobile {
            line-height: 20px;
            font-size: 10px;
            text-transform: capitalize;
            letter-spacing: 1px;
        }
        &:before {
            content: "-";
        }
    }

    &-RatingSummary {
        line-height: 1;

        @include desktop {
            min-width: 180px;
            width: fit-content;
        }

        @include mobile {
            width: 100%;
        }

        &_isAvailable{
            @include desktop {
                border-left: 1px dashed rgb(222, 222, 222);
                margin-left: 20px;
                padding-left: 20px;
            }
            @include mobile {
                border-top: 1px dashed rgb(222, 222, 222);
                padding-top: 10px;
            }
        }
    }

    &-RatingSummaryItem {
        margin-block-end: 16px;
        @include desktop{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @include mobile {
            display: grid;
            grid-template-columns: 1fr 3fr;
            justify-content: flex-start;
            margin-block-end: 7px;
        }

        &:last-of-type {
            margin-block-end: 0;
        }

        .ProductReviewRating {
            --star-size: 20px;

            margin-block: 0 2px;
            margin-inline: 12px 0;

            @include mobile {
                margin-block: 0 3px;
                margin-inline: 14px 0;
            }
        }
    }

    &-ReviewContent {
        flex: 1 0 0;
        margin-inline-end: 48px;

        @include mobile {
            flex: 0 0 auto;
            margin-inline-start: 0;
            width: 100%;
            order: 3;
        }

        @include tablet {
            flex: auto;
            order: 15;
            margin-block-start: 18px;
        }

        .TextPlaceholder {
            display: block;
        }
    }

    &-ReviewAuthor {
        margin-block: 15px 0;
        font-size: 12px;
    }

    &-ReviewDetails {
        margin-block-end: 0;
        text-align: justify;
        letter-spacing: 1px;
        @include mobile {
            margin-block-end: 20px;
        }

        .TextPlaceholder {
            margin-block-start: 6px;
            line-height: 1.1;

            @include mobile {
                margin-block-start: 7px;
            }

            &::after {
                display: block;
            }
        }
    }

    .ProductReviewRating-Counter {
        display: none;
    }

    &-RatingItem {
        margin-block: auto;
        letter-spacing: 1px;
    }
}
