/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin narrow-tablet {
    @media (min-width: 650px) and (max-width: 811px) {
        @content;
    }
}

.ProductContent {
    margin: 20px 5px;
    box-sizing: border-box;
    @include mobile {
        overflow: hidden;
    }
    //HORIZONTAL LINE CLASS STYLE SECTION

    .horizontalLine,
    .IngredientHorizontalLine {
        position: absolute;
        bottom: -5px;
        height: 1px;
        background-color: var(--primary-alt-light-color);
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 2.2em;
    }

    .IngredientHorizontalLine {
        margin-bottom: 0.5em;
    }

    //ALL IMAGE SECTION STYLE

    .imgSuperFoodChoice {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        @include desktop {
            img {
                width: 15%;
                height: 100%;
            }
        }

        @include mobile {
            img {
                width: 25%;
                height: 100%;
            }
        }
        @include narrow-tablet {
            img {
                width: 20%;
                height: 100%;
            }
        }
    }

    .imgBenefit {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;

        @include desktop {
            img {
                width: 13%;
                height: 100%;
                min-width: 186px;
                min-height: 186px;
            }
        }

        @include mobile {
            img {
                width: 25%;
                height: 100%;
                min-width: 87px;
                min-height: 87px;
            }
        }
        @include narrow-tablet {
            img {
                width: 20%;
                height: 100%;
                min-width: 152px;
                min-height: 152px;
            }
        }
    }

    .imgIngredient {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4em;

        @include desktop {
            img {
                width: 12.5%;
                height: 100%;
                min-width: 179px;
                min-height: 179px;
            }
        }

        @include mobile {
            img {
                width: 25%;
                height: 100%;
                min-width: 87px;
                min-height: 87px;
            }
        }
        @include narrow-tablet {
            img {
                width: 20%;
                height: 100%;
                min-width: 152px;
                min-height: 152px;
            }
        }

        .imgleaf {
            width: 100%;
            position: absolute;
            display: flex;
            justify-content: center;

            @include desktop {
                img {
                    width: 6.5%;
                    height: 100%;
                    top: -10px;
                    height: 100%;
                    min-width: 93px;
                    min-height: 93px;
                }
            }

            @include mobile {
                img {
                    top: -5px;
                    width: 15%;
                    height: 100%;
                    min-width: 53px;
                    min-height: 53px;
                }
            }
            @include narrow-tablet {
                img {
                    width: 10%;
                    top: -10px;
                    height: 100%;
                    min-width: 76px;
                    min-height: 76px;
                }
            }
        }
    }

    .imgbgImprove {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 4em;

        @include desktop {
            img {
                width: 10%;
                min-width: 143px;
                min-height: 143px;
            }
        }

        @include mobile {
            img {
                width: 17%;
                min-width: 60px;
                min-height: 60px;
            }
        }

        @include narrow-tablet {
            img {
                width: 10%;
                min-width: 76px;
                min-height: 76px;
            }
        }
    }

    .imgthumbsUp {
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 25%;

        @include desktop {
            img {
                width: 5%;
                min-width: 72px;
                min-height: 72px;
            }
        }

        @include mobile {
            img {
                width: 10%;
                min-width: 38px;
                min-height: 38px;
            }
        }

        @include narrow-tablet {
            img {
                width: 5%;
                min-width: 35px;
                min-height: 35px;
            }
        }
    }

    .imgbgControl {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 4em;

        @include desktop {
            img {
                width: 10%;
                min-width: 143px;
                min-height: 143px;
            }
        }

        @include mobile {
            img {
                width: 17%;
                min-width: 60px;
                min-height: 60px;
            }
        }

        @include narrow-tablet {
            img {
                width: 10%;
                min-width: 76px;
                min-height: 76px;
            }
        }
    }

    .imgthumbsDown {
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 25%;

        @include desktop {
            img {
                width: 5%;
                min-width: 72px;
                min-height: 72px;
            }
        }

        @include mobile {
            img {
                width: 10%;
                min-width: 38px;
                min-height: 38px;
            }
        }

        @include narrow-tablet {
            img {
                width: 5%;
                min-width: 35px;
                min-height: 35px;
            }
        }
    }

    .imgReceipe {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 4em;

        @include desktop {
            img {
                width: 10%;
            }
        }

        @include mobile {
            img {
                width: 20%;
            }
        }

        @include narrow-tablet {
            img {
                width: 10%;
            }
        }
    }

    .imgbgMessageIcon {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 4em;

        @include desktop {
            img {
                width: 10%;
            }
        }

        @include mobile {
            img {
                width: 20%;
            }
        }

        @include narrow-tablet {
            img {
                width: 10%;
            }
        }
    }

    .imgFaqIcon {
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 23%;
        @include desktop {
            img {
                width: 5%;
            }
        }

        @include mobile {
            img {
                width: 10%;
            }
        }

        @include narrow-tablet {
            img {
                width: 5%;
            }
        }
    }

    //COMMON PARENT CONTAINER

    &-SuperFoodChoice,
    &-KeyBenefits,
    &-Ingredients,
    &-Improves,
    &-Controls,
    &-Receipe,
    &-ProductFaq {
        @include desktop {
            margin-block: 10em;
        }
        @include mobile {
            margin-block: 2em;
        }
        @include narrow-tablet {
            margin-block: 5em;
        }
    }

    //COMMON HEADING H3 TAG STYLE

    &-SuperFoodMeaningTitle,
    &-SuperFoodTitle,
    &-KeyBenefitsTitle,
    &-IngredientTitle,
    &-ImprovesTitle,
    &-ControlsTitle,
    &-ReceipeTitle,
    &-ProductFaqTitle {
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            margin-top: 0px !important;
            display: flex;
            justify-content: center;
            text-align: center;
            font-family: var(--font-riffic);
            text-transform: uppercase;
            font-weight: 400;
            color: var(--primary-alt-dark-color);
            border-radius: 50%;
            cursor: pointer;

            @include desktop {
                padding: 20px 10px;
                font-size: 23px !important;
                letter-spacing: 2px;
            }

            @include mobile {
                padding: 10px 10px;
                font-size: 15px !important;
                letter-spacing: 1px;
            }

            @include narrow-tablet {
                font-size: 20px !important;
            }
        }
    }

    // SEPERATE TITLE SECTION TO CHANGE ANY PROPERTY
    &-IngredientTitle {
        h3 {
            margin-bottom: 0px !important;
        }
    }

    // SUPERFOOD MEANING SECTION

    &-SuperFoodMeaning {
        padding-bottom: 5em;
    }
    &-SuperFoodMeaningLeftQuote {
        position: absolute;
        z-index: 2;
        @include desktop {
            left: 15%;
            top: 0%;
            width: 45px !important;
        }
        @include mobile {
            left: 6%;
            top: 0%;
            width: 35px !important;
        }
        @include narrow-tablet {
            left: 5%;
            top: 0%;
            width: 35px !important;
        }
    }
    &-SuperFoodMeaningRightQuote {
        position: absolute;
        z-index: 2;
        @include desktop {
            right: 15%;
            bottom: 0%;
            width: 45px !important;
        }
        @include mobile {
            right: 6%;
            bottom: 0%;
            width: 35px !important;
        }
        @include narrow-tablet {
            right: 5%;
            bottom: 0%;
            width: 35px !important;
        }
    }
    .SuperFoodMeaningContainer {
        display: flex;
        justify-content: center;
        .SuperFoodMeaningWrapper {
            background-color: beige;
            padding: 40px 0px;
            border-radius: 30px;
            @include desktop {
                width: 70%;
            }
            @include mobile {
                width: 90%;
                box-shadow: 0px 0px 15px 0px rgb(114, 114, 114),
                    rgba(0, 0, 0, 0.06) 0px 2px 1px,
                    rgba(0, 0, 0, 0.09) 3px 4px 2px,
                    rgba(0, 0, 0, 0.09) 2px 8px 4px,
                    rgba(0, 0, 0, 0.09) -2px 16px 8px,
                    rgba(0, 0, 0, 0.09) 0px 10px 16px;
            }
            @include narrow-tablet {
                width: 90%;
            }

            .SuperFoodMeaningText {
                p {
                    color: grey;
                    text-align: center;
                    text-transform: capitalize;
                    font-style: italic;
                    @include desktop {
                        letter-spacing: 2px;
                        line-height: 40px;
                        padding-inline: 20px;
                        margin-block: 1em;
                        font-size: 18px;
                    }
                    @include mobile {
                        padding-inline: 20px;
                        margin-block: 1em;
                        font-size: 15px;
                        letter-spacing: 1px;
                        line-height: 30px;
                        text-align: justify;
                    }
                    @include mobile {
                        padding-inline: 20px;
                        margin-block: 1em;
                        font-size: 16px;
                        letter-spacing: 1px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
    //WHY SUPERFOOD SECTION STYLE

    &-SuperFoodContainer {
        @include desktop {
            justify-items: center;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 5px;
            grid-row-gap: 50px;
            margin-inline: 25px;
        }
        @include mobile {
            margin: 0;
        }
        @include narrow-tablet {
            justify-items: center;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 5px;
            grid-row-gap: 30px;
        }
    }

    &-SuperFoodWrapper {
        display: flex;
        align-items: center;

        @include desktop {
            flex-direction: column;
            align-items: center;
            padding: 20px;
        }

        @include mobile {
            padding: 10px 20px;
        }
        @include narrow-tablet {
            flex-direction: column;
            align-items: center;
            padding: 20px;
        }
    }

    &-SuperFoodContentIcon {
        @include desktop {
            padding-bottom: 2em;
            width: 60%;
        }

        @include mobile {
            width: 20%;
        }

        @include narrow-tablet {
            padding-bottom: 2em;
            width: 60%;
        }
    }

    &-SuperFoodContentText {
        text-align: center;
        @include desktop {
            text-align: center;
            line-height: 22px;
            letter-spacing: 1px;
            font-size: 18px;
        }

        @include mobile {
            line-height: 18px;
            letter-spacing: 0.4px;
            margin-left: 1em;
            font-size: 15px;
            text-align: left;
        }

        @include narrow-tablet {
            text-align: center;
            line-height: 20px;
            font-size: 16px;
            letter-spacing: 0.7px;
            margin: 0;
        }
    }
    .SuperFoodAccordionWrapper {
        @include desktop {
            display: flex;
            justify-content: center;
            margin-top: 3em;
        }
        @include mobile {
            display: flex;
            justify-content: center;
        }
        @include narrow-tablet {
            display: flex;
            justify-content: center;
        }
        .SuperFoodAccordion {
            @include desktop {
                width: 70%;
            }
            @include mobile {
                width: 98%;
            }
            @include narrow-tablet {
                width: 90%;
            }
        }
    }

    //BENEFITS SECTION STYLE
    &-KeyBenefitsContainer {
        display: grid;
        @include desktop {
            min-height: 100vh;
            z-index: 1;
            justify-items: center;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 50px;
            grid-row-gap: 50px;
            margin-inline: 25px;
            margin-top: 3em;
        }
        // @include mobile {
        //     margin: 0;
        // }
        @include ultra-narrow-desktop {
        //     justify-items: center;
        //     display: grid;
            grid-template-columns: repeat(3, 1fr);
        //     grid-column-gap: 5px;
        //     grid-row-gap: 30px;
        }
    }
    .KeyBenefitsWrapper {
        @include desktop {
            perspective: 600px;
            &:hover {
                .ProductContent-KeyBenefitsInnerWrapper {
                    transform: rotateY(180deg);
                }
                .ProductContent-KeyBenefitsBackView {
                    span {
                        opacity: 1;
                    }
                }
                .ProductContent-KeyBenefitsFrontView {
                    opacity: 0;
                }
                .ProductContent-KeyBenefitsBackViewTitle {
                    transform: translateY(-40px);
                    opacity: 1;
                }
            }
        }
        @include tablet {
            perspective: 1000;
        }

    }

    &-KeyBenefitsInnerWrapper {
        @include desktop {
            transition: transform 0.6s;
            transform-style: preserve-3d;
        }
    }
    &-KeyBenefitsFrontView,
    &-KeyBenefitsBackView {
        display: flex;
        align-items: center;

        @include desktop {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            transition: transform 0.6s;
            transform-style: preserve-3d;
        }

        @include mobile {
            padding: 10px 20px;
        }
        // @include narrow-tablet {
        //     flex-direction: column;
        //     align-items: center;
        //     padding: 20px;
        // }
    }
    &-KeyBenefitsBackViewTitle {
        position: absolute;
        top: 0px;
        opacity: 0;
        transition: all 0.8s linear;
        transform: translateY(0);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: beige;
        border-radius: 15px;
        padding-top: 3px;
        span {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
            color: var(--primary-alt-base-color);
        }
    }
    &-KeyBenefitBackTitle {
        text-align: center;
        span {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
            color: var(--primary-alt-base-color);
        }
    }
    .hrLine {
        border-top: 1px solid grey;
        width: 100%;
        margin-block: 1em;
    }
    &-KeyBenefitsBackView {
        @include desktop {
            // min-width: 308px;
            span {
                text-align: left;
                letter-spacing: 0.5px;
                line-height: 20px;
                font-size: 13px;
                opacity: 0;
            }

            position: absolute;
            top: 0;
            height: 30vh;
            z-index: 0;
            overflow: scroll;
            transform: rotateY(180deg);
            background-color: rgb(209, 214, 194);
            border-radius: 15px;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.6) inset,
                0px 2px 10px 0px rgba(0, 0, 0, 0.6),
                0px 5px 10px 0px rgba(0, 0, 0, 0.06),
                0px 9px 15px 0px rgba(0, 0, 0, 0.3),
                0px 9px 15px 0px rgba(0, 0, 0, 0.1);
        }
        @include mobile {
            display: none;
        }
    }

    &-KeyBenefitIcon {
        img {
            background-color: beige;
            border-radius: 30%;
            padding: 10px;
            @include desktop {
                min-width: 107px;
                min-height: 107px;
            }
        }
        @include desktop {
            padding-bottom: 2em;
            width: 40%;
            min-width: 107px;
            min-height: 135px;
        }

        @include mobile {
            width: 20%;
        }

        @include narrow-tablet {
            padding-bottom: 2em;
            width: 40%;
        }
    }

    &-KeyBenefitText {
        @include desktop {
            text-align: center;
            line-height: 22px;
            letter-spacing: 1px;
            font-size: 16px;
            transition: all 0.3s linear;
            min-width: 175px;
            min-height: 45px;
        }

        @include mobile {
            line-height: 18px;
            letter-spacing: 0.4px;
            margin-left: 1em;
            font-size: 15px;
            text-align: left;
        }

        @include narrow-tablet {
            text-align: center;
            line-height: 20px;
            font-size: 16px;
            letter-spacing: 0.7px;
            margin: 0;
        }
    }

    &-KeyBenefitMoreInfoIcon {
        @include desktop {
            background-color: var(--primary-alt-light-color);
            border-radius: 50%;
            width: 22px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 1em;
        }
    }

    &-HorizontalLine {
        @include desktop {
            position: absolute;
            left: 37%;
            border-bottom: 1px solid grey;
            width: 25%;
            margin-top: 3em;
        }
        @include mobile {
            position: absolute;
            left: 25%;
            border-bottom: 1px solid grey;
            width: 50%;
            margin-top: 0;
        }
        @include narrow-tablet {
            position: absolute;
            left: 25%;
            border-bottom: 1px solid grey;
            width: 50%;
            margin-top: 0;
        }
    }

    &-KeyBenefitsBtnWrapper,
    &-IngredientsBtnWrapper,
    &-NutritionBtnWrapper {
        @include desktop {
            margin-block: 4em;
            display: flex;
            justify-content: center;
        }
        @include mobile {
            margin-block: 4em;
            display: flex;
            justify-content: center;
        }
        @include narrow-tablet {
            margin-block: 5em;
            display: flex;
            justify-content: center;
        }
    }

    &-KeyBenefitsBtn,
    &-IngredientsBtn {
        text-align: center;
        background-image: radial-gradient(
            circle at center center,
            rgb(235, 233, 229) 10%,
            rgb(209, 214, 194) 30%
        );
        padding-block: 2em;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.1) 0px 2px 20px, rgba(0, 0, 0, 0.2) 3px 4px 20px;
        margin-inline: 10px;
        border-radius: 15px;
        @include desktop {
            width: 70%;
            margin: 0;
        }
        @include mobile {
            padding-inline: 10px;
            width: 90%;
        }
        @include narrow-tablet {
            width: 90%;
        }
        p {
            font-size: 18px;
            line-height: 35px;
            letter-spacing: 0.5px;
            margin-block: 1em;
            padding-inline: 20px;
            color: var(--primary-alt-dark-color);
            @include mobile {
                font-size: 15px;
            }
            @include narrow-tablet {
                font-size: 16px;
            }
        }
        .Button {
            background-color: var(--primary-dark-color);
            border-radius: 10px;
            box-shadow: 0px 0px 15px rgb(160, 160, 160);
            letter-spacing: 3px;
            transition: all 0.2s linear;
            @include mobile {
                letter-spacing: 2px;
                width: 100%;
            }
            @include narrow-tablet {
                letter-spacing: 2px;
                width: 50%;
            }

            &:hover {
                box-shadow: 0px 0px 20px rgb(160, 160, 160);
                background-color: var(--primary-base-color);
                border-radius: 10px;
            }
        }
    }
    &-NutritionBtn {
        .Button {
            background-color: var(--primary-alt-light-color);
            box-shadow: 0px 0px 10px rgb(171, 171, 171);
            border-radius: 10px;
            border: none;
            letter-spacing: 3px;
            transition: all 0.2s linear;
            @include mobile {
                letter-spacing: 2px;
                width: 100%;
            }
            @include narrow-tablet {
                letter-spacing: 2px;
                width: 100%;
            }

            &:hover {
                box-shadow: 0px 0px 30px rgb(157, 157, 157) !important;
                background-color: var(--primary-alt-light-color) !important;
                border-radius: 10px !important;
            }
        }
    }
    &-IngredientsBtn {
        background-image: none;
        box-shadow: none;
    }

    &-KeyBenefitsBtn {
        .Button {
            border: none;
            &:hover {
                border: none;
            }
        }
    }

    // USED & UNUSED INGREDIENTS SECTION STYLE

    &-SubHeadingIngredient {
        h5 {
            margin-bottom: 2em;
            display: flex;
            justify-content: center;
            text-align: center;
            font-family: var(--font-avenir);
            text-transform: uppercase;
            font-weight: 400;
            color: grey;
            border-radius: 50%;
            cursor: pointer;
            padding: 10px 10px;
            font-size: 15px !important;
            letter-spacing: 0.5px;
        }
    }

    &-IngredientsContainer {
        @include desktop {
            display: flex;
            justify-content: center;
        }

        @include mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        @include narrow-tablet {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
        }
    }

    &-VerticalLine {
        @include desktop {
            border-left: 2px dashed var(--primary-alt-light-color);
        }

        @include narrow-tablet {
            border-left: 2px dashed var(--primary-alt-light-color);
        }
    }

    &-UiContainer {
        background-image: radial-gradient(
            circle at center center,
            beige,
            rgba(43, 76, 50, 0.4)
        );
        @include desktop {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
        }

        @include mobile {
            border-radius: 30px;
            margin-bottom: 2em;
        }

        @include narrow-tablet {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            margin: 0;
        }
    }

    &-UnUiContainer {
        background-image: radial-gradient(
            circle at center center,
            beige,
            rgba(160, 79, 26, 0.4)
        );

        @include desktop {
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
        }

        @include mobile {
            border-radius: 30px;
            margin-top: 2em;
        }

        @include narrow-tablet {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            margin-top: 0em;
        }
    }
    .EmptyUnUi {
        @include desktop {
            border-radius: 30px;
            width: 50%;
        }
    }

    &-UiContainer,
    &-UnUiContainer {
        @include desktop {
            width: 35%;
            min-width: 405px;
            max-width: 405px;
        }

        @include mobile {
            box-shadow: 0px 0px 15px 0px rgb(114, 114, 114),
                rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 3px 4px 2px,
                rgba(0, 0, 0, 0.09) 2px 8px 4px,
                rgba(0, 0, 0, 0.09) -2px 16px 8px,
                rgba(0, 0, 0, 0.09) 0px 10px 16px;
            width: 90%;
        }

        @include narrow-tablet {
            box-shadow: none;
            width: 45%;
            max-width: 340px;
        }
    }

    &-UiWrapper,
    &-UnUiWrapper {
        button[disabled]{
            color: black;
        }
        @include mobile {
            padding: 10px 0px;
        }
        @include narrow-tablet {
            padding: 0px;
        }
    }

    &-UiTitle {
        h3 {
            color: var(--primary-base-color);
        }
    }

    &-UnUiTitle {
        h3 {
            color: var(--primary-alt-dark-color);
        }
    }

    &-UiTitle,
    &-UnUiTitle {
        h3 {
            display: flex;
            justify-content: center;
            text-align: center;
            font-family: var(--font-riffic);
            text-transform: uppercase;
            font-weight: 400;
            border-radius: 50%;
            cursor: pointer;

            @include desktop {
                padding: 20px 10px;
                font-size: 20px !important;
                letter-spacing: 2px;
            }

            @include mobile {
                padding: 10px 10px;
                font-size: 15px !important;
                letter-spacing: 1px;
            }

            @include narrow-tablet {
                font-size: 18px !important;
            }
        }
    }
    &-UiTagLine,
    &-UnUiTagLine {
        text-align: center;
        p {
            font-size: 18px;
            letter-spacing: 1px;
            margin: 0;
            color: white;
            text-transform: uppercase;
            padding: 10px 0;
        }
    }
    &-UiTagLine {
        background-color: var(--primary-base-color);
    }
    &-UnUiTagLine {
        background-color: var(--primary-alt-base-color);
    }

    &-UiContent,
    &-UnUiContent {
        padding: 15px 20px;
        cursor: pointer;
        @include desktop {
            display: grid;
            grid-template-columns: 0.7fr 1fr 0.5fr;
            align-items: center;
            &:hover {
                img {
                    filter: blur(0px) brightness(115%);
                    -webkit-filter: blur(0px) brightness(115%);
                    -moz-filter: blur(0px) brightness(115%);
                    transform: scale(1.2);
                }
            }
        }

        @include mobile {
            display: grid;
            grid-template-columns: 0.7fr 1fr 0.5fr;
            align-items: center;
        }

        @include narrow-tablet {
            display: grid;
            grid-template-columns: 0.7fr 1fr 0.5fr;
            align-items: center;
        }
    }

    &-UiIcon {
        background-image: radial-gradient(
            circle at center center,
            beige,
            rgba(155, 160, 156, 0.5) 80%
        );
        box-shadow: 0px 0px 15px 0px inset rgb(147, 147, 147);
    }
    &-UnUiIcon {
        background-image: radial-gradient(
            circle at center center,
            rgba(95, 95, 95, 0.6) 30%,
            beige 80%
        );
        box-shadow: 0px 0px 15px 0px inset rgb(219, 218, 218);
    }

    &-UiIcon,
    &-UnUiIcon {
        margin-right: 1em;
        border-radius: 50%;

        @include desktop {
            min-width: 83px;
            min-height: 83px;
            margin: 0;
            width: 68px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.2s linear;
            
            img {
                transition: all 0.15s linear;
                width: 100%;
                min-width: 72px;
                min-height: 72px;
                padding: 7px;
                filter: blur(0px) brightness(80%);
                -webkit-filter: blur(0px) brightness(80%);
                -moz-filter: blur(0px) brightness(80%);
            }
        }
        @include mobile {
            min-width: 80px;
            min-height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                min-width: 55px;
                min-height: 55px;
                width: 100%;
                padding: 10px;
            }
        }
        @include narrow-tablet {
            // width: 80px;
            img {
                width: 100%;
                padding: 5px;
            }
        }
    }

    &-UiText,
    &-UnUiText {
        @include desktop {
            line-height: 18px;
            letter-spacing: 0.5px;
            width: 166px;
            // width: 100%;
            font-size: 16px;
            text-align: left;
            // margin-left: 1em;
        }
        @include mobile {
            line-height: 18px;
            letter-spacing: 0.5px;
            font-size: 15px;
            text-align: left;
            margin-left: 10px;
            width: 115px;
        }
        @include narrow-tablet {
            line-height: 18px;
            letter-spacing: 0.5px;
            font-size: 15px;
            text-align: left;
            width: 140px;
        }
    }
    &-UiInfoBtn,
    &-UnUiInfoBtn {
        text-align: end;
    }

    

    //IMPROVES AND CONTROLS SECTION STYLE

    .ImproveCard,
    .ControlCard {
        @include desktop {
            perspective: 1000px;
            transition: all 0.2s linear;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            &:hover {
                z-index: 5;
                background-image: radial-gradient(
                    circle at center bottom,
                    beige,
                    rgba(44, 76, 50, 0.5) 80%
                );
                transform: translateY(-10px);
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                .ProductContent-ImproveCardFront,
                .ProductContent-ControlCardFront {
                    z-index: -1;
                }
                .ProductContent-ImproveCardBack,
                .ProductContent-ControlCardBack {
                    z-index: 5;
                    background-image: radial-gradient(
                        circle at center top,
                        rgb(203, 169, 128),
                        rgb(203, 169, 128)
                    );

                    box-shadow: rgba(0, 0, 0, 0.1) 0px -20px 15px 0px inset,
                        rgba(0, 0, 0, 0.5) 0px 20px 15px 0px;
                    padding: 11px;
                    // height: auto;
                    width: 100%;
                    display: block;
                    inset-block-start: 100%;
                    opacity: 1;
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
                .ProductContent-ImproveMoreInfoIcon,
                .ProductContent-ControlMoreInfoIcon {
                    opacity: 0 !important;
                }
                .ProductContent-ImproveText,
                .ProductContent-ControlText {
                    font-weight: 700;
                    min-height: auto;
                    color: var(--primary-alt-base-color);
                }
            }
        }
    }
    &-ImproveCardBack,
    &-ControlCardBack {
        height: 30vh;
        overflow: scroll;
    }

    &-ImproveCardFront,
    &-ImproveCardBack,
    &-ControlCardFront,
    &-ControlCardBack {
        transition: all 0.4s linear;
        @include desktop {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            display: flex;
            padding: 10px;
            margin: 5px 0px;
        }
    }

    &-ImproveMoreInfoIcon,
    &-ControlMoreInfoIcon {
        @include desktop {
            background-color: var(--primary-alt-light-color);
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 1em;
        }
        @include mobile {
            display: none;
        }
    }

    &-ImproveCardBack,
    &-ControlCardBack {
        @include desktop {
            width: 100%;
            transition: all 0.3s ease-in-out;
            z-index: -1;
            padding: 10px;
            opacity: 0;
            position: absolute;
            inset-block-start: 0%;
            display: none;
        }
    }
    &-ImprovesContainer,
    &-ControlsContainer {
        display: grid;
        @include desktop {
            min-width: 100vh;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 50px;
            grid-row-gap: 25px;
            margin-inline: 25px;
        }
    }
    .AccordionWrapper {
        @include mobile {
            justify-self: center;
            width: 98%;
        }
        @include narrow-tablet {
            justify-self: center;
            width: 90%;
        }
    }

    &-ImproveIcon,
    &-ControlIcon {
        @include desktop {
            transition: all 0.2s linear;
            padding-bottom: 2em;
            width: 111px;
            min-width: 111px;
            min-height: 111px;
            img {
                min-width: 111px;
                min-height: 111px;
            }
        }

        @include mobile {
            width: 100%;
        }

        @include narrow-tablet {
            width: 40%;
        }
    }

    &-ImproveText,
    &-ControlText {
        transition: all 0.2s linear;
        @include desktop {
            text-align: center;
            line-height: 22px;
            letter-spacing: 0.5px;
            font-size: 18px;
            min-height: 45px;
        }
    }
    &-ImproveBackText,
    &-ControlBackText {
        @include desktop {
            text-align: center;
            line-height: 25px;
            letter-spacing: 0.5px;
            font-size: 16px;
            color: white;
        }
    }

    //Receipe Section
    &-ReceipeContainer {
        display: flex;
        justify-content: center;
    }
    &-ReceipeWrapper {
        background-color: rgba(185, 199, 177, 0.3);
        border-radius: 30px;
        padding: 30px 5em;
        margin: 10px 0px;
        @include mobile {
            padding: 30px 2em;
            margin: 10px 10px;
            border-radius: 10px;
            box-shadow: 0px 0px 15px 0px grey;
            width: 90%;
        }
        @include desktop {
            width: 70%;
        }
        @include narrow-tablet {
            width: 90%;
        }
        ul {
            padding-block: 10px;
            li {
                display: flex;
                align-items: flex-start;
                margin: 0;
                padding-block: 10px;
                span {
                    background-color: var(--primary-alt-light-color);
                    font-weight: bold;
                    font-size: 17px;
                    letter-spacing: 1px;
                    color: white;
                    text-align: center;
                    padding: 5px 10px;
                    border-radius: 10px;
                    // align-self: center;
                    @include mobile {
                    }
                }
                p {
                    align-self: center;
                    font-size: 15px;
                    letter-spacing: 0.5px;
                    color: grey;
                    margin-left: 1em;
                }
                &::before {
                    content: "";
                }
            }
        }
    }

    //FAQ Section
    &-ProductFaqContainer {
        @include desktop {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        @include narrow-tablet {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    &-ProductFaqSection {
        @include desktop {
            width: 70%;
        }
        @include mobile {
            width: 98%;
        }
        @include narrow-tablet {
            width: 90%;
        }
    }
}
