/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.GroupedProductsItem {
    &-productsItemDesktopWrapper {
        display: grid;
        align-items: center;
        grid-template-columns: 72px 3fr 1fr;
        grid-gap: 18px;
        background-color: rgb(226, 236, 233);
        box-shadow: 3px 3px 3px rgb(181, 180, 180) inset,
            -3px -3px 3px rgb(255, 255, 255) inset;
        border-radius: 10px;
        padding: 10px 20px;

        &::before {
            content: none;
        }

        &:last-child {
            margin-block-end: 0;
        }

        &:not(:last-of-type) {
            margin-block-end: 15px;
        }
    }

    &-productsItemMobileWrapper {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 14px;
        align-items: center;
        border-bottom: 1px solid gray;
        &::before {
            content: none;
        }

        &:last-child {
            margin-block-end: 0;
        }
        &:not(:last-of-type) {
            margin-block-end: 15px;
        }
        @media screen and (max-width: 400px) {
            &:not(:last-of-type) {
                margin-block-end: 10px;
            }
            .Image_ratio_square {
                padding: 0;
            }
        }
    }

    &-Image {
        @media screen and (max-width: 400px) {
            width: 60px;
            height: 60px;
        }
        &::after {
            content: "";
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
            inset-inline-start: 0;
            inset-block-end: 0;
            background-color: var(--product-card-picture-mask-color);
            mix-blend-mode: multiply;
            pointer-events: none;
        }
    }

    &-Title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow: hidden;
        @include desktop {
            align-items: flex-start;
        }

        @include mobile {
            padding-block-start: 14px;
            padding-block-end: 14px;
        }

        p {
            margin-block-end: 12px;
            margin-block-start: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            @include desktop {
                white-space: nowrap;
                max-width: 100%;
                font-size: 17px;
                letter-spacing: 1px;
            }

            @include mobile {
                white-space: nowrap;
                font-size: 14px;
                letter-spacing: 1px;
                line-height: 17px;
                margin-bottom: 5px;
                max-width: 100%;
            }
        }
        .ProductPrice_hasDiscount {
            @include tablet {
                display: flex;
                flex-direction: column;
                del {
                    margin-right: 0;
                    margin-bottom: 5px;
                }
                margin-right: 0;
            }
        }
    }

    &-PriceAndQtyWrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .ProductPrice_hasDiscount {
            display: flex;
            flex-direction: column;
            del {
                margin-right: 0;
            }
        }
    }

    &-Quantity .Field {
        margin: 0;
    }

    &-Quantity {
        input {
            @include mobile {
                min-width: 36px;
            }
        }
    }
}
