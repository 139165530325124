/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductReviewForm {
    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-gap: 24px;
        }
    }

    &-RatingWrapper {
        display: flex;
        flex-direction: column;
    }

    &-ProductName {
        font-weight: 700;
        font-size: 24px;

        @include mobile {
            font-size: 25px;
        }
    }

    &-Content {
        label {
            letter-spacing: 1px;
        }
        @include mobile {
            margin-block-start: 28px;
        }
    }

    &-Rating {
        [dir="rtl"] & {
            display: flex;
            flex-direction: row-reverse;
        }

        margin-block-start: 12px;
        margin-block-end: 16px;
        margin-inline: 0;
        text-align: start;
        direction: rtl;
        border: 0;
        /* stylelint-disable-next-line unknownProperties, property-no-unknown */
        white-space-collapse: discard; // alternative of `font-size: 0`

        @include mobile {
            margin-block-start: 14px;
            margin-block-end: 18px;
            margin-inline: 0;
        }

        &:first-child {
            @include mobile {
                margin-block-start: 0;
            }
        }
    }

    &-Legend {
        border-block-end: 0;
        padding-block-end: 4px;
        font-weight: 700;
        font-size: 13px;
        letter-spacing: 1px;
        border-bottom: 1px dashed rgb(237, 237, 237);
        padding-bottom: 5px;
        margin-bottom: 5px;
        @include mobile {
            padding-block-end: 4px;
            font-size: 15px;
        }
    }

    & &-Button {
        margin-block-start: 12px;
        letter-spacing: 1px;
        @include desktop {
            border: none !important;
            border-radius: 10px !important;
        }
        &:hover {
            border: none !important;
            background-color: var(--primary-light-color) !important;
            @include desktop {
                border-radius: 10px !important;
            }
        }
        @include mobile {
            margin-block-start: 28px;
            width: 100%;
        }
    }

    input,
    textarea {
        border-radius: 10px;
        width: 100%;
    }
}
