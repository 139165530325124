/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductReviews {
    @include desktop {
        z-index: 1;
    }

    .ExpandableContent-Button {
        @include mobile {
            background-color: antiquewhite;
            padding: 14px !important;
            border-radius: 10px;
        }
    }

    &-Wrapper {
        padding: 0;

        @include desktop {
            padding: 36px 12px;
        }
        @include mobile {
            padding: 0 !important;
        }
    }

    &-SummaryContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mobile {
            margin-top: 20px;
        }
    }

    &-SummaryRating {
        --star-size: 27px;

        .ProductReviewRating-Counter {
            display: none;
        }
    }

    &-ExpandableContentHeading,
    &-ExpandableContentButton {
        @include desktop {
            display: none;
        }
    }

    &-ExpandableContentContent {
        @include desktop {
            margin-block-start: 0;
        }

        &_isContentExpanded {
            padding-block-start: 0;
        }
    }

    &-ReviewActionContainer {
        @include desktop {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            border-radius: 50%;
            background-color: var(--primary-light-color);
            box-shadow: 0px 0px 10px rgb(164, 164, 164);
        }
        @include mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            border-radius: 50%;
            background-color: var(--primary-light-color);
        }

        &_noReview {
            z-index: 10;
            @include desktop {
                float: right;
                right: 0;
            }
            @include mobile {
                float: right;
                right: 0;
                top: 10px;
            }
        }
    }

    &-ReviewButton {
        color: var(--primary-light-color);
        cursor: pointer;
        @include desktop {
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
        }
        @include mobile {
            width: 23px;
            height: 23px;
            min-width: 23px;
            min-height: 23px;
        }
    }

    &-RatingDataDetails {
        @include desktop {
            display: flex;
            align-items: center;
        }
        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            row-gap: 15px;
        }
    }

    &-SummaryDetails {
        display: inline-block;
        margin-inline-start: 12px;
        margin-block: auto;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1px;
        @include mobile {
            margin-inline-start: 0px;
            font-size: 18px;
            color: var(--primary-alt-light-color);
        }
        @include desktop {
            font-size: 20px;
            color: var(--primary-alt-light-color);
        }

        span {
            @include mobile {
                font-size: 15px;
                color: gray;
            }
            @include desktop {
                font-size: 16px;
                color: gray;
            }
            &::before {
                content: " / ";
            }
        }
    }
    &-NoReviewDataImage {
        text-align: center;

        @include desktop {
            margin-block: 2em;
            width: 100%;
            height: 100%;
            .successImg {
                width: 250px;
                height: 250px;
            }
            p {
                margin-top: 2em;
                font-size: 17px;
                letter-spacing: 1px;
                font-weight: 600;
                color: var(--primary-alt-base-color);
            }
        }
        @include mobile {
            margin-block: 1em;
            width: 100%;
            height: 100%;
            min-width: 150px;
            min-height: 150px;
            .successImg {
                width: 50%;
            }
            p {
                margin-top: 2em;
                font-size: 17px;
                letter-spacing: 1px;
                line-height: 30px;
                font-weight: 600;
                color: var(--primary-alt-base-color);
            }
        }
    }
}
