/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductLinks {
    width: 100%;

    @include mobile {
        margin-block-start: 14px;
    }

    @include desktop {
        grid-column: 1 / span 2;
    }

    &-Wrapper {
        padding-block: 0;
        @include desktop {
            margin-block: 20px;
        }
        @include mobile {
            padding-inline: 0;
        }

        &_isCartPageLinkedProduct {
            @include desktop {
                margin-block: 0px;
            }
        }
    }

    &-List {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: 1fr 1fr;

        @include mobile {
            grid-gap: 14px;
        }

        @include desktop {
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 24px;
            justify-content: center;
        }

        @include narrow-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        &_isCartPageLinkedProduct {
            @include desktop {
                display: block;
            }
            @include mobile {
                display: block;
            }
        }
    }

    &-Title {
        line-height: 1;

        @include mobile {
            padding: 12px 10px;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 1px;
            color: var(--primary-alt-base-color);
            text-transform: uppercase;

            &::after {
                content: "";
                height: 1px;
                width: 100%;
                background-color: var(--primary-alt-base-color);
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        @include desktop {
            padding: 12px 10px;
            font-weight: 600;
            font-size: 17px;
            letter-spacing: 1px;
            color: var(--primary-alt-base-color);
            text-transform: uppercase;
            &::after {
                content: "";
                height: 1px;
                width: 50%;
                background-color: var(--primary-alt-base-color);
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        &_isCartPageLinkedProduct {
            @include mobile {
                padding: 12px 10px;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 1px;
                color: var(--primary-alt-base-color);
                border-radius: 10px;
                text-transform: capitalize;
                text-align: center;
                &::after {
                    content: "";
                    display: none;
                }
            }

            @include desktop {
                padding: 12px 10px;
                font-weight: 600;
                font-size: 15px;
                letter-spacing: 1px;
                color: var(--primary-alt-base-color);
                border-bottom: 1px solid var(--primary-alt-base-color);
                text-transform: capitalize;
                &::after {
                    content: "";
                    display: none;
                }
            }
        }
    }

    .Image_imageStatus_1 {
        mix-blend-mode: multiply;
    }
}
