/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// util class, is applied on <html> on fullscreen image
.overscrollPrevented {
    overscroll-behavior: contain;
}

.ProductGallery {
    display: flex;
    flex-direction: column;
    height: 500px;
    margin-inline: 45px;

    // @media screen and (min-width: 1026px) and (max-width: 1100px) {
        //     width: 50%;
        // }
        // @include narrow-desktop {
            //     min-width: 507px;
            // }
            
            @include mobile {
        margin-inline: 0px;
        // height: calc(100vh / 2);
        margin-block-end: 16px;
    }

    &-Additional {
        flex: 0 0 auto;

        @include mobile {
            display: none;
        }
        &_isImageZoomPopupActive {
            position: absolute;
            inset-block-end: 20px;
            inset-inline-start: 20px;

            .CarouselScrollItem {
                filter: opacity(0.75);

                &_isActive {
                    border-width: 2px;
                    filter: opacity(1);
                }
            }
        }
        &_isWithEmptySwitcher {
            height: 66px;
        }
    }

    &-SliderWrapper {
        flex: 1;
    }

    &-Slider {
        height: 100%;
        opacity: var(--sliderOpacity);
        &_isImageZoomPopupActive {
            cursor: pointer;
        }

        &_isZoomInCursor {
            cursor: zoom-in;
        }
    }

    .react-transform-component,
    .react-transform-element,
    &-SliderImage {
        height: 100% !important;
        width: 100% !important;
    }
}
