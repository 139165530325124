/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, 0.7);
    --product-card-reviews-background: rgba(243, 243, 243, 0.55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, 0.78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(
        --product-card-wishlist-button-background
    );
    --product-card-compare-button-color: var(
        --product-card-wishlist-button-color
    );
    --product-card-name-line-height: 1.2em;
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 32px;
    --product-card-background: var(--color-white);
    --option-margin-block: 0 12px;
    --option-margin-inline: 0 12px;
}

// .ProductCard:nth-child(2n) {
//     @include desktop {
//         background-image: linear-gradient(120deg, #f4f5ef 50%, #ffffff 50%);
//     }
// }
// .ProductCard:nth-child(2n + 1) {
//     @include desktop {
//         background-image: linear-gradient(120deg, #ffffff 50%, #f4f5ef 50%);
//     }
// }

.ProductCard {
    height: 100%;
    min-width: 0;
    display: flex;
    align-items: stretch;
    @include desktop {
        min-height: 370px;
        transition: all 0.2s linear;
        background-image: linear-gradient(120deg, #f4f5ef 50%, #ededed 50%);
        // border: 1px solid lightgray;
        border-radius: 20px;
        box-shadow: 5px 10px 10px rgb(155, 155, 155);
    }
    @include mobile {
        min-height: 250px;
        box-shadow: -5px -5px 7px rgb(255, 255, 255),
            5px 5px 7px rgb(188, 188, 188);
        border-radius: 15px;
        // border: 1px solid lightgray;
        background-color: rgb(226, 236, 233);
        transition: all 0.3s linear;
        &:active {
            box-shadow: -5px -5px 7px rgb(255, 255, 255) inset,
                5px 5px 7px rgb(188, 188, 188) inset;
        }
    }

    &_isLinkedProduct {
        @include mobile {
            flex-direction: column;
            background-color: rgb(245, 245, 245);
            box-shadow: 0px 0px 10px rgb(230, 230, 230),
                0px 0px 10px rgb(188, 188, 188);
        }
    }
    &:hover {
        @include desktop {
            box-shadow: 0px 0px 10px 5px rgba(186, 132, 22, 0.5);
            -webkit-box-shadow: 0px 0px 10px 5px rgba(186, 132, 22, 0.5);
            background: rgb(237, 237, 237);
            z-index: 12;
            transform: translateY(8px);

            .ProductCard-MainDetails {
                background-color: var(--primary-light-color);
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                padding: 15px;
                top: 0;
                width: 100%;
            }

            .ProductCard-Name,
            .ProductCard-ProductTagLine,
            .ProductCard-ProductWeight {
                color: white;
            }

            .ProductCard-FigureReview {
                padding: 0;
            }
            .ProductCard-Figure {
                transform: translateY(5px) scale(0.9);
                padding-inline: 15px;
            }

            .ProductCard-Price {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                background-color: transparent;
                padding-bottom: 10px;
                color: black;
                .ProductPrice-HighPrice {
                    color: red;
                }
            }

            .ProductCard-VisibleOnHover {
                background: rgb(237, 237, 237);
                width: inherit;
                opacity: 1;
                box-shadow: 10px 10px 10px -3px rgba(186, 132, 22, 0.5),
                    -10px 10px 10px -3px rgba(186, 132, 22, 0.5);
                -webkit-box-shadow: 10px 10px 10px -3px rgba(186, 132, 22, 0.5),
                    -10px 10px 10px -3px rgba(186, 132, 22, 0.5);
                z-index: -1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex-grow: 1;
                padding-block-start: 0;
                padding-block-end: 10px;
                padding-inline: 15px;
                position: absolute;
                top: 95%;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
            }

            .ProductCard-ProductDetailWrapper {
                .left {
                    animation: fadeInLeft 0.5s;
                }
                .down {
                    animation: fadeIn 0.5s;
                }
                .right {
                    animation: fadeInRight 0.5s;
                }
            }
            .ProductCard-ProductTagLine {
                animation: zoomIn 0.5s;
            }
        }
    }

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 7px;

        @include mobile {
            margin-block-end: 8px;
        }
    }

    &-Brand {
        font-weight: 400;
        margin-block-end: 4px;
        opacity: 0.5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);
        font-size: 12px;
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-ProductDetailContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        padding-bottom: 5px;
    }

    &-Reviews {
        --product-rating-size: 16px;
        --product-rating-background: #ffff;
        width: 100%;
        border-radius: 10px;
        letter-spacing: normal;

        @include mobile {
            --product-rating-size: 14px;
            width: 100%;
            border-radius: 0;
            height: 18px;
            margin-bottom: 7px;
        }

        @include desktop {
            height: 25px;
        }
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        min-height: calc(var(--product-card-color-size) + 5px);
    }

    &-Color,
    &-Image {
        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 0;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 10px;
        margin-block-end: 0;
    }

    .ProductPrice-PriceBadge {
        font-size: 12px;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover,
        &:focus {
            text-decoration: none;
            color: initial;
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-VisibleOnHover {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
        padding-block-start: 0;
        padding-block-end: 10px;
        padding-inline: 15px;
        opacity: 0;
        position: absolute;
        inset-block-start: 100%;
        display: none;
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }

    // MODIFIED CODE

    // @@CORE CODE MODIFIED

    &-Content {
        padding-block: 10px 5px;
        display: flex;
        flex-direction: column;
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        line-height: var(--product-card-name-line-height);
        margin: 0;
        padding-block-end: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        @include mobile {
            white-space: nowrap;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            font-weight: 700;
            letter-spacing: 0.5px;
            line-height: 25px;
        }
        @include desktop {
            text-align: center;
            line-height: 25px;
            font-size: 18px;
            letter-spacing: 0.5px;
            text-transform: capitalize;
        }

        &_isCartPageLinkedProduct {
            @include desktop {
                font-size: 15px;
                letter-spacing: 1px;
                font-weight: 700;
                text-align: left;
                display: flex;
                align-items: center;
            }
            @include mobile {
                font-size: 11px;
                letter-spacing: 1px;
                font-weight: 700;
                text-align: left;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }

    &-Picture {
        @include desktop {
            margin: 10px 0px !important;
            height: 150px !important;
        }
        @include mobile {
            margin-top: 25px !important;
            margin-bottom: 10px !important;
            padding-block-end: 80% !important;
        }

        &_isCartPageLinkedProduct {
            @include desktop {
                margin: 0px !important;
                min-width: 80px !important;
                min-height: 80px !important;
                width: 80px !important;
                height: 80px !important;
            }
            @include mobile {
                margin: 0px !important;
                padding: 0 !important;
                min-width: 50px !important;
                min-height: 50px !important;
                width: 50px !important;
                height: 50px !important;
            }
        }
    }

    &-PriceWrapper {
    }

    &-Price {
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        color: var(--primary-light-color);
        @include desktop {
            font-size: 17px;
            letter-spacing: 1px;
            text-align: center;
            color: white;
            background-color: rgba(186, 132, 22, 0.75);
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        @include mobile {
            font-weight: 600;
            font-size: 16px;
        }
        &_isCartPageLinkedProduct {
            background-color: transparent;
            border-radius: 0px;
            color: var(--primary-alt-light-color);
            @include mobile {
                font-size: 13px;
                font-weight: 400;
            }
        }
    }

    &-ProductActions {
        padding-top: 5px;
        .ProductWishlistButton {
            @include desktop {
                float: right;
                right: 10px;
            }
            @include mobile {
                position: absolute;
                top: 5px;
                right: 2%;
                z-index: 10;
            }
        }
    }

    &-FigureReview {
        // background: var(--product-card-background);
        display: flex;
        flex-direction: column;
        justify-content: center;
        // @include desktop {
        padding-inline: 15px;
        // }
    }

    &-Figure {
        flex-grow: 1;
        transition: all 0.2s linear;
        @include desktop {
            transform: scale(0.7);
        }
        @include mobile {
            // background-color: rgba(187, 187, 187, 0.2);
            // border-top-left-radius: 15px;
            // border-top-right-radius: 15px;
            border-radius: 15px;
        }
    }

    &-Footer {
        width: 100%;
    }

    //##ADDED CODE

    &-MainDetails {
        @include desktop {
            text-align: center;
            padding: 15px;
        }

        @include mobile {
            margin-bottom: 5px;
        }
    }

    &-ProductWeight {
        font-weight: 100;
        text-align: center;
        font-size: 15px;
        letter-spacing: 1px;
        line-height: 20px;
        @include mobile {
            font-size: 12px;
        }
        &_isCartPageLinkedProduct {
            @include desktop {
                font-size: 12px;
                color: gray;
                text-align: left;
            }
            @include mobile {
                font-size: 12px;
                color: gray;
                margin-left: 5px;
            }
        }
    }

    &-ProductTagLine {
        font-size: 13px;
        font-style: italic;
        font-weight: 100;
        margin: 5px 0px;
        letter-spacing: 0.5px;
        width: 100%;
        @include desktop {
            color: grey;
            line-height: 20px;
            text-align: center;
        }
        @include mobile {
            color: var(--primary-alt-light-color);
            line-height: 16px;
            text-align: left;
        }

        &_isCartPageLinkedProduct {
            text-align: left;
        }
    }

    &-PriceContainer {
        position: absolute;
        bottom: 0;
        width: 100%;
        @include mobile {
            display: none;
        }
    }

    &-ProductInfoWrapper {
        margin-top: 1em;
    }

    &-ProductDetail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0px;
        overflow: hidden;
    }

    &-Item {
        text-align: center;
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            letter-spacing: 0.5px;
            font-weight: 400;
        }
    }

    &-ProductItem {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 10px;
    }

    &-ItemLabel {
        letter-spacing: 0.5px;
        font-weight: 500;
        color: var(--primary-alt-dark-color);
    }

    &-ItemDetail {
        color: var(--color-white);
        margin-top: 10px;
        font-weight: 700;
        background-color: var(--primary-alt-dark-color);
        border-radius: 30px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-AddToCart {
        @include desktop {
            border-radius: 30px !important;
            background-color: var(--primary-light-color) !important;
            border: none !important;
            color: white !important;
            transition: all 0.2s linear;
            letter-spacing: 1px;
            &:hover {
                background-color: var(--primary-base-color) !important;
                border: none !important;
                border-radius: 30px !important;
                color: var(--primary-alt-light-color) !important;
            }
        }
    }

    &-ActionFooter {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        > * {
            margin-inline-end: 5px;
        }

        @include mobile {
            margin-top: 10px;
            .AddToCart {
                width: 100px;
                border-radius: 30px !important;
                font-size: 13px;
            }
        }
        &_isCartPageLinkedProduct {
            justify-content: flex-end;
            @include mobile {
                margin-top: 0px;
            }
        }
        &_isLinkedProduct {
            @include mobile {
                .AddToCart {
                    margin-inline-end: 0;
                    height: auto;
                    padding-block: 7px;
                    font-size: 11px;
                    min-width: 135px !important;
                }
            }
        }
    }
}
.singleItemClass {
    justify-content: center !important;
}
.ProductLinkedCard {
    height: 100%;
    min-width: 150px;
    display: flex;
    align-items: stretch;
    &:before {
        content: "";
    }
    @include desktop {
        min-height: 200px;
        transition: all 0.2s linear;
        background-image: linear-gradient(120deg, #f4f5ef 50%, #ededed 50%);
        border-radius: 20px;
        box-shadow: 0px 0px 10px rgb(213, 213, 213);
    }
    @include mobile {
        min-height: 250px;
        border-radius: 15px;
        background-color: rgb(226, 236, 233);
        transition: all 0.3s linear;
    }

    &_isLinkedProduct {
        @include mobile {
            flex-direction: column;
        }
    }

    &_isCartPageLinkedProduct {
        @include desktop {
            display: block;
            min-height: 100%;
            min-width: 100%;
            background-color: transparent;
        }
        @include mobile {
            background-color: transparent;
            min-height: auto;
        }
    }

    &-Wrapper {
        display: grid;
        grid-gap: 1px;
        background: var(--color-white);
        align-items: center;
        padding: 5px 3px;
        margin: 7px 0px;
        @include desktop {
            grid-template-columns: 1fr 3fr 0.8fr;
            border-radius: 15px;
            margin-block: 10px;
            padding: 5px 10px;
            box-shadow: 0px 0px 5px lightgray;
        }
        @include mobile {
            grid-template-columns: 1fr 3fr 0.8fr !important;
            border-radius: 15px;
            margin-block: 0px;
            padding: 5px 10px;
            box-shadow: 0px 0px 10px rgb(223, 223, 223);
        }
    }

    &-CartItemRows {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-width: 0;
    }

    &-ProductInfo {
        object-position: top;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &-ProductActions {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &-FigureReview {
        // background: var(--product-card-background);
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include desktop {
            // align-items: flex-start;
        }
        padding-inline: 15px;
        &_isCartPageLinkedProduct {
            padding: 0;
        }
    }

    &-Figure {
        transition: all 0.2s linear;
        @include desktop {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @include mobile {
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
