/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --product-rating-background: #979797;
    --product-rating-filled-background: var(--primary-base-color);
    --product-rating-size: 18px;

    @include mobile {
        --product-rating-size: 21px;
    }
}

.ProductReviewRating {
    display: flex;
    align-items: flex-end;
    line-height: normal;

    @include desktop {
        justify-content: center;
        margin-top: -6px;
    }

    &_isLoading {
        &::before {
            background-image: var(--placeholder-image);
            background-size: var(--placeholder-size);
            animation: var(--placeholder-animation);
        }
    }

    &-Counter {
        display: inline-block;
        font-size: 12px;
        letter-spacing: 0;
        font-weight: 700;
    }
}
