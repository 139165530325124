/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ProductListPage {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: max-content;
    grid-column-gap: 12px;

    @include tablet {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mobile {
        grid-column-gap: 14px;
        grid-template-columns: 1fr 1fr;
        border: 0;
        padding-inline: 10px;
    }

    @include desktop {
        grid-column: 2;
    }

    &-Offset {
        @include mobile {
            height: 150vh;
            inset-block-start: -150vh;
        }

        height: 100vh;
        pointer-events: none;
        inset-block-start: -100vh;
        position: absolute;
        inset-inline-start: 0;
        width: 100%;

        &::before {
            display: none;
        }
    }

    &-CategoriesProduct {
        margin-block: 5em;
        @include mobile {
            margin-block: 2em;
        }
    }
    &-CategoriesHeading {
        @include mobile{
            display: flex;
            justify-content: center;
            width: auto;
        }
        h1 {
            font-size: 25px;
            color: var(--primary-alt-base-color);
            letter-spacing: 1.5px;
            margin-block: 1em;
            padding: 5px 0px;
            border-bottom: 1px solid #e0e0e0;
            @include mobile {
                letter-spacing: 1px;
                font-weight: 100;
                font-size: 18px;
                text-align: center;
                border: none;
                background-color: rgb(236,219,211);
                border-radius: 30px;
                padding-inline: 15px;
                color: var(--primary-light-color);
            }
        }
    }
}
