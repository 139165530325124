/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --price-color: #{$black};
    --price-with-discount-color: #b10d0d;
    --price-discount-color: #808080;
}

.ProductPrice {
    color: var(--price-color);
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    vertical-align: middle;
    margin-block-end: 0;
    @include desktop {
        display: flex;
        justify-content: center;
        align-items: center;
        // min-height: 40px;
        padding: 10px 0;
    }

    @include mobile {
        font-size: 18px;
    }

    &-Price {
        @include desktop {
            text-decoration: none;
            flex-wrap: wrap;
        }
        @include mobile {
            text-decoration: none;
            display: inline-flex;
            align-items: baseline;
            flex-wrap: wrap;
        }
    }

    del {
        font-size: 14px;
        font-weight: 500;
        display: inline-block;
        margin-inline-end: 12px;
    }

    &-HighPrice {
        @include desktop {
            color: rgb(255, 255, 255);
        }
        @include mobile {
            color: black;
        }
    }

    &-PriceValue {
        margin-block-start: 6px;
    }

    &-Placeholder {
        &::after {
            content: "aaaaa";
        }
    }

    &-SubPrice {
        display: block;
        font-weight: 400;
        font-size: 12px;
        margin-block-start: 4px;
    }

    &-PriceBadge {
        font-size: 14px;
        font-weight: 400;
        margin-inline-end: 4px;
        white-space: nowrap;
    }
}
