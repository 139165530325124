/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
html {
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 0;
    height: 0;
}

// /* Track */
// ::-webkit-scrollbar-track {
// }

// // /* Handle */
// ::-webkit-scrollbar-thumb {
//     background: rgb(160, 160, 160);
//     border-radius: 10px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//     background: rgb(108, 108, 108);
// }
.ProductPage {
    @include tablet {
        padding-block-start: 0;
        margin-block-start: 0;
    }

    @include mobile {
        padding-block-end: 133px;
        margin-inline: 16px;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-template-rows: min-content min-content;
        -webkit-padding-before: 24px;
        padding-top: 24px;
        @include desktop {
            min-height: 100vh;
            background-image: radial-gradient(
                142% 280% at center left,
                transparent 35%,
                rgb(226, 236, 233) 35%
            );
            grid-column-gap: 48px;
            grid-template-areas: ". actions";
            -webkit-padding-after: 72px;
            padding-bottom: 72px;
            .ProductActions {
                grid-area: actions;
            }
            margin-bottom: 3em;
        }

        @include mobile {
            grid-template-columns: 100%;
            padding: 0;
        }

        > .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    &-NaturalSweetnerImage {
        position: absolute;
        background-color: rgb(225, 235, 233);
        border-radius: 50%;
        @include desktop {
            top: -20px;
            left: 3px;
            width: 90px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                padding: 5px;
                width: 81px;
                height: 81px;
                min-width: 80px;
                min-height: 80px;
            }
        }
        @include mobile {
            top: 0px;
            left: -13px;
            width: 90px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                padding: 8px !important;
                width: 80px;
                height: 80px;
                min-width: 80px;
                min-height: 80px;
            }
        }
    }
}
